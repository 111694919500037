#pageHeader {
    width: 100%;

    @include media-breakpoint-up(md) {
        //min-height: 500px;
        max-height: 650px;
    }

    .bg-logo {
        @include media-breakpoint-up(md) {
            overflow: hidden;
            background-image: url(../assets/img/stensen-logo-fill.svg);
            background-repeat: no-repeat;
            background-position: center right;
        }
    }

    overflow:hidden;

    .event-feat-img {
        object-fit: cover;
    }

    .header-desc-event {
        padding: 1rem;

        @include media-breakpoint-up(md) {
            padding: 5rem;
        }

        flex-direction: column;

        .event-title {
            @include media-breakpoint-up(lg) {
                text-indent: -50px;
                font-size: 3rem;
            }

            @include media-breakpoint-up(xl) {
                font-size: 3.5rem;
                max-width: 550px;
            }

            width: 100%;
            max-width: 500px;
            font-size: 2.5rem;
            span {
                font-size: 2.5rem;
                white-space: nowrap;
                @include media-breakpoint-up(lg) {
                    font-size: 3rem;
                }
                @include media-breakpoint-up(xl) {
                    font-size: 3.5rem;
                }
            }
        }

        .event-short-desc {
            max-width: 550px;
        }
    }
}

// Pagina 404
#error-404-wrapper {
    height: 50vh;
    display: flex;
    align-items: center;
}