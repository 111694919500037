.mec-calendar.mec-calendar-timetable .mec-calendar-d-table dl dt.mec-timetable-day-active {
    background-color: $blue;

    &:after {
        border-color: $blue transparent transparent transparent;
    }
}

.mec-calendar.mec-calendar-weekly .mec-calendar-d-table dl {
    width: 100%;
}

#fullCalendar {
    .event-color {
        width: 25px;
        height: 10px;
        border-radius: unset;
    }
}