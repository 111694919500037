// Fonts
//@import url("https://use.typekit.net/ojh8tfo.css");
//
// Headings
//
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6, .primary {
    text-transform: $heading-text-uppercase;
}

p {
    font-size: 1rem;
    @include media-breakpoint-up(md) {
        font-size: 1.19rem;
    }
}

span {
    font-size: 1rem;
    @include media-breakpoint-up(md) {
        font-size: 1.19rem;
    }
}

.primary {
    color: $gray-100;
}