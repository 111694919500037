.section-heading {
  position: relative;
  display: flex;
  align-items: center;
  &:after {
    content: "";
    position: absolute;
    z-index: 0;
    right: 15px;
    width: 50%;
    max-width: 700px;
    height: 50px;
    @include media-breakpoint-up(md) {
      width: 40%;
    }
    background-color: $secondary;
  }
  .section-title {
    display: inline-block;
    margin-bottom: 0;
    font-size: 2.5rem;
    color: $section-title;
    position: relative;
    z-index: 3;
  }
}

button:focus {
  outline: none;
}

.cta-btn {
  background-color: $blue;
  transition: all 100ms ease-out;
  opacity: 1;
  color: $light;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 0;
  font-size: 13px;
  max-width: 200px;
  width: 100%;
  &:hover {
    opacity: 0.8;
    color: $light;
  }
}

.single-event-row:nth-child(even) {
  background-color: $gray-100;
}

.thumb-container {
  max-width: 200px;
  .thumb-calendar {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.btn-basic-white {
  @extend .btn-basic;
  color: $secondary;
  text-decoration: underline;
  &:hover {
    color: $secondary;
  }
  &:after {
    content: url(../assets/img/arrow_slider_right.svg) !important;
  }
}
.btn-basic {
  &:after {
    content: url(../assets/img/arrow_basic_right.svg);
    position: relative;
    width: 5px;
    height: 5px;
    left: 5px;
    opacity: 0;
    display: inline-block;
    transition: all 100ms ease-out;
  }
  &:hover {
    &:after {
      opacity: 1;
      left: 15px;
    }
  }
}

// @include media-breakpoint-up(sm) {
//   .container {
//     max-width: 650px;
//   }
// }
// @include media-breakpoint-up(md) {
//   .container {
//     max-width: 800px;
//   }
// }
// @include media-breakpoint-up(lg) {
//   .container {
//     max-width: 900px;
//   }
// }
// @include media-breakpoint-up(xl) {
//   .container {
//     max-width: 1250px;
//   }
// }

// @include media-breakpoint-up(xxl) {
//   .container {
//     max-width: 1500px;
//   }
// }

.data-start {
  font-family: $headings-font-family;
  font-size: 1.2rem;
  @include media-breakpoint-up(md) {
    font-size: 1.4rem;
  }
}

#main {
  padding-top: 67px;
}

.print-btn {
  cursor: pointer;
}

#anchorLinkNewsletter {
  cursor: pointer;
  @extend .btn-basic;
  text-decoration: underline;
  &:after {
    content: url(../assets/img/arrow_basic_down.svg);
    left: 10px;
    width: 10px;
    height: 10px;
  }
}

.active-link {
  text-decoration: underline;
}

.singolo-orario + .singolo-orario {
  &::before {
    content: ",";
  }
}

table {
  @extend .table;
}

.fw-bold {
  font-weight: 700;
}

.disable-link {
  pointer-events: none;
}

.obj-cov {
  object-fit: cover;
}
