#news {
    margin-bottom: 3rem;
    padding-bottom: 3rem;
    .news-cont {
        &:nth-child(3n+2) {
            @include media-breakpoint-up(md){
                padding-top: 4rem;
             }
        }
        &:nth-child(3n+3) {
            @include media-breakpoint-up(md){
                padding-top: 8rem;
             }
        }
    }
}


.single-news {
    margin-bottom: 3rem;
    @include media-breakpoint-up(md){
        margin-bottom: 3rem;
    }
    a {
        display: block;
        position: relative;
        overflow: hidden;
        max-height: 200px;
        .single-news-overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
            background-color: $primary;
            transition: opacity 200ms ease-in-out;
            &:hover {
                opacity: 0.4;
            }
        }
    }
    .cat-indicator {
        width: 15px;
        height: 10px;
        @include media-breakpoint-up(md){
            width: 25px;
            height: 10px;
        }
    }
    .single-news-cat-name {
        font-size: 0.9rem;
        @include media-breakpoint-up(md){
            font-size: 1.1rem;
        }
    }
    .single-news-title {
        font-size: 1.4rem;
        @include media-breakpoint-up(md){
            font-size: 1.575rem;
        }
    }
    .single-news-desc {
        font-size: 1rem;
        @include media-breakpoint-up(md) {
            font-size: 1.19rem;
        }
    }
}

#postHeader {
    width: 100%;
    @include media-breakpoint-up(md) {
        //min-height: 500px;
        max-height: 650px;
    }
    overflow:hidden;
    .post-feat-img {
        object-fit: cover;
    }
    .header-desc-post {
        padding: 1rem;

        @include media-breakpoint-up(md) {
            padding: 5rem;
        }

        flex-direction: column;

        .post-title {
            @include media-breakpoint-up(lg) {
                text-indent: -50px;
                font-size: 3rem;
            }

            @include media-breakpoint-up(xl) {
                font-size: 3.5rem;
                max-width: 550px;
            }

            width: 100%;
            max-width: 500px;
            font-size: 2.5rem;
        }

        .post-short-desc {
            max-width: 550px;
        }
    }
}