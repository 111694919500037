/*
* Use existing Bootstrap 4 classes and
* variables to extend - override CF7 style
*
* Useful CF7 classes:
* .wpcf7 the wrapper element
* .wpcf7-form
* .wpcf7-form-control
* .wpcf7-text
* .wpcf7-email
* .wpcf7-textarea
* .wpcf7-submit
*/

// keep a max width in case it is just the form and nothing else
// we do not want a form spanning whole page
.wpcf7 {
  max-width: 600px;
  margin: 0 auto !important;

  // all inputs except radios and checkboxes inherit from form-control
  input[type=text],
  input[type=search],
  input[type=url],
  input[type=tel],
  input[type=number],
  input[type=range],
  input[type=date],
  input[type=month],
  input[type=week],
  input[type=time],
  input[type=datetime],
  input[type=datetime-local],
  input[type=color],
  input[type=email],
  input[type=file],
  input[type=submit],
  select,
  textarea {
    @extend .form-control;
  }

  // submit button, inherit .btn and .btn-outline-primary classes.
  input[type=submit] {
    @extend .btn;
    @extend .btn-outline-primary;
  }

  .wpcf7-form {

    // set paragraphs to behave like divs with class .form-group
    p {
      @extend .form-group;
    }

    // let labels be 100%
    label {
      width: 100%;
    }
  }

  // not valid tip for each control
  .wpcf7-not-valid-tip {
    color: theme-color("danger");
  }

  // validation errors ourput bottom of form
  .wpcf7-validation-errors {
    @extend .form-control;
    color: theme-color("danger");
    border: 1px solid $gray-200;
  }
}

span.wpcf7-list-item {
  display: block;
  margin: 0;
    label {
      position: relative;
    }

}

.privacy-check {
  cursor: inherit;
  opacity: 0;
  position: absolute;
}

.wpcf7-list-item-label {
  padding-left: 30px;
  &::selection {
    background: none;
  }
}

.link-privacy {
  text-decoration: underline;
}

.wpcf7-list-item-label:before {
    border: 1px solid $blue;
    content: "";
    height: 18px;
    top: 2px;
    left: 0;
    position: absolute;
    width: 18px;
    cursor: pointer;
}

.wpcf7-list-item-label:after {
  background-color: $blue;
  transition: all 150ms ease-in-out;
  content: "";
  width: 8px;
  height: 8px;
  position: absolute;
  cursor: pointer;
  display: block;
  background-color: $blue;
  top: 7px;
  left: 5px;
  transform: scale(0);
}

input:checked~.wpcf7-list-item-label:after {  
  transform: scale(1);
}

.submit-form {
  margin-top: 3rem;
}