#programmazione {
  margin-bottom: 2rem;
  //padding-bottom: 3rem;
  .event-cont {
    @include media-breakpoint-only(md) {
      &:nth-child(3n + 2) {
        padding-top: 2rem;
      }
      &:nth-child(3n + 3) {
        padding-top: 4rem;
      }
    }

    @include media-breakpoint-up(lg) {
      &:nth-child(4n + 2) {
        padding-top: 2rem;
      }
      &:nth-child(4n + 3) {
        padding-top: 4rem;
      }
      &:nth-child(4n + 4) {
        padding-top: 6rem;
      }
    }
  }
}

.single-event {
  margin-bottom: 3rem;
  @include media-breakpoint-up(md) {
    margin-bottom: 3rem;
  }
  a {
    display: block;
    position: relative;
    overflow: hidden;
    max-height: 200px;
    .single-event-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      background-color: $primary;
      transition: opacity 200ms ease-in-out;
      &:hover {
        opacity: 0.4;
      }
    }
  }
  .cat-indicator {
    width: 15px;
    height: 10px;
    @include media-breakpoint-up(md) {
      width: 25px;
      height: 10px;
    }
  }
  .single-programma-cat-name {
    font-size: 0.9rem;
    @include media-breakpoint-up(md) {
      font-size: 1.1rem;
    }
  }
  .single-event-title {
    font-size: 1.4rem;
    @include media-breakpoint-up(md) {
      font-size: 1.575rem;
    }
  }
  .single-event-desc {
    font-size: 1rem;
    @include media-breakpoint-up(md) {
      font-size: 1.19rem;
    }
  }
}
