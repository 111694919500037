#eventHeader {
    width: 100%;
    @include media-breakpoint-up(md) {
        //min-height: 500px;
        max-height: 650px;
    }
    overflow:hidden;
    .event-feat-img {
        object-fit: cover;
    }
    .header-desc-event {
        padding: 1rem;

        @include media-breakpoint-up(md) {
            padding: 5rem;
        }

        flex-direction: column;

        .event-title {
            @include media-breakpoint-up(lg) {
                text-indent: -50px;
                font-size: 3rem;
            }

            @include media-breakpoint-up(xl) {
                font-size: 3.5rem;
                max-width: 550px;
            }

            width: 100%;
            max-width: 500px;
            font-size: 2.5rem;
        }

        .event-short-desc {
            max-width: 550px;
        }
    }
}

#description {
    .entry-content {
        width: 100%;

        @include media-breakpoint-up(md) {
            width: 80%;
        }
    }
}

#gallery {
    margin-bottom: 5rem;

    .gallery-carousel {
        .slick-slide {
            @include media-breakpoint-up(sm) {
                margin-right: 20px;
            }
            //width: 300px !important;
        }

        .slick-list {
            //margin: 0 20px;
            //overflow: visible;
            // &:before {
            //     content: '';
            //     position: absolute;
            //     height: 100%;
            //     width: 300px;
            //     background-color: $white;
            //     left: -300px;
            //     z-index: 3;
            // }

        }

        .slick-arrow {
            position: absolute;
            bottom: -50px;
            width: 50px;
            height: 50px;
            background-color: $blue;
            transition: background-color 250ms ease-in-out;
            font-size: 0;
            line-height: 0;
            display: block;
            cursor: pointer;
            border: none;
            outline: none;

            &.slick-disabled {
                background-color: darken($blue, 10%);
            }
        }

        .slick-next {
            left: 65px;
        }

        .slick-prev:before {
            content: url(../assets/img/arrow_slider_left.svg);
            color: $white;
        }

        .slick-next:before {
            content: url(../assets/img/arrow_slider_right.svg);
            color: $white;
        }
    }
}

#collaborations {
    .main-collab {
        max-width: 270px;
    }

    .logo-collab {
        max-width: 220px;
    }
}