.searched-query {
    //display: block;
    font-size: 3rem;
    color: $primary;
    font-style: italic;
    text-transform: lowercase;
    &:after,
    &:before {
        content: '"';
    }
}

#searchInput {
    #searchform {
        padding: 0;
        .field {
            height: 68px;
            border-radius: 0;
            border-width: 2px;
            border-color: $gray-500;
            border-right: none;
            &:focus {
                outline: none;
                border-color: $primary;
            }
        }
        .submit {
            height: 68px;
            border-radius: 0;
            width: 68px;
        }
    }
}


#search-wrapper {
    margin-bottom: 3rem;
    padding-bottom: 3rem;
    .event-cont {
        &:nth-child(3n+2) {
            @include media-breakpoint-up(md){
                padding-top: 4rem;
             }
        }
        &:nth-child(3n+3) {
            @include media-breakpoint-up(md){
                padding-top: 8rem;
             }
        }
    }
}

