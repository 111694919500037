// div {
//   margin: 0;
//   padding: 0;
//   outline: none;
// }

.slider-container {
  position: relative;

  //background-color: $primary;
  .slider-cat-container {
    position: absolute;
    right: 0;
    top: 0;
    width: 68px;
    height: calc(100% - 25px);
    z-index: 2;
    //background-color: $yellow;
    overflow: hidden;

    .single-cat-color {
      width: 0;
      height: 100%;
      right: 0;
      position: absolute;
      overflow: hidden;
      transition: width 500ms cubic-bezier(0.77, 0.01, 0.15, 1) 1s;

      span.single-cat-name {
        color: $white;
        transform-origin: center;
        transform: translate(-50%, -50%) rotate(-90deg);
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 1rem;
        text-transform: uppercase;
        font-family: $font-family-heading;
        font-weight: $font-weight-normal;
        opacity: 0;
        transition: opacity 200ms linear 1.2s;
        width: 200px;
        text-align: center;
      }

      &.cat-active {
        width: 100%;

        span.single-cat-name {
          opacity: 1;
          
        }
      }
    }
  }
}

.slider-holder {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}

.slider {
  position: relative;
  width: 100%;

  @include media-breakpoint-up(md) {
    width: 60%;
    min-height: 500px;
    max-height: 650px;
  }

  height: 60vh;
  background-color: $primary;
  overflow:hidden;
}

.slider-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 35%;
    display: block;
    background-image: linear-gradient(to bottom, unquote('#00000000'), #000);
    bottom: 0;

    @include media-breakpoint-up(md) {
      width: 35%;
      height: 100%;
      background-image: linear-gradient(to right, unquote('#00000000'), #000);
      right: 0;
      top: 0;
    }
  }

  .slide-title__small {
    position: absolute;
    color: $white;
    z-index: 4;
    bottom: 0;
    opacity: 0;
    width: 100%;
    padding-bottom: 1rem;
    text-align: center;
    font-size: 2rem;
    transition: opacity 500ms linear;
    .slide-title__small-link {
      color: $white;
    }
  }

  &.active {
    .slide-title__small {
      opacity: 1;
    }
  }

  .img-protector {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
}

.slider-img {
  display: block;
  object-fit: cover;
  width: 100%;
  object-position: 50% 50%;
  height: 100%;

}

.slide-area-info {
  width: 40%;
  position: relative;
  background-color: $primary;
  background: linear-gradient(to top, $primary 0%,#202020 100%);
  display: flex;
  align-items: center;

  &:before {
    content: '';
    position: absolute;
    height: 25px;
    background-color: $primary;
    width: 160%;
    bottom: -25px;
    right: 0;
  }

  .slide-info {
    margin-left: -100px;
    position: absolute;
    width: 100%;
    height: 100%;

    .slide-txt-info {
      opacity: 0;
      transition: opacity 500ms linear 200ms;
      position: absolute;
      top: 50%;
      transform: translateY(-40%);
      z-index: 6;
      visibility: hidden;
      &.activeTxt {
        opacity: 1;
        visibility: visible;
      }
      .single-event-desc {
        color: $white;
        max-width: 450px;
      }
    }

    .slide-title {
      @include media-breakpoint-up(lg) {
        text-indent: -100px;
        font-size: 3rem;
      }
      @include media-breakpoint-up(xl) {
        font-size: 3.5rem;
        max-width: 600px;
      }
      //position: absolute;
      //z-index: 3;
      color: $white;
      opacity: 1;
      //top: 50%;
      //left: 50%;
      //transform: translate(-50%, -50%);
      width: 100%;
      max-width: 500px;
      font-size: 2.5rem;
      .slide-title-link {
        color: $white;
      }
    }
  }
}

.slide-area-info__small {
  position: relative;
  background-color: $primary;
  width: 100%;
  padding-top: 3rem;
  padding-bottom: 3rem;
  .slide-date__small {
    position: absolute;
    color: $white;
    z-index: 3;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    width: 100%;
    text-align: center;
    font-size: 1.2rem;
    transition: opacity 500ms linear;
    .data-end:before {
      content: '';
      display: inline-block;
      width: 0;
      height: 0;
      border-top: .375rem solid transparent;
      border-bottom: .375rem solid transparent;
      border-left: .625rem solid $white;
      margin: 0 5px;
    }
    &.activeTxt__small {
      opacity: 1;
    }
  }
}





/*
            Navigation
        */
.slider-nav {
  display: flex;
  position: relative;
  top: -25px;
  z-index: 6;
}

.slider-nav-btn {
  cursor: pointer;
  background-color: $blue;
  transition: all .2s;
  width: 50px;
  height: 50px;
}


.slider-nav-btn:last-child {
  margin-right: 0;
}

.go-prev:after {
  content: url(../assets/img/arrow_slider_left.svg);
  color: $white;
}

.go-next:after {
  content: url(../assets/img/arrow_slider_right.svg);
  color: $white;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: $primary;
  background: linear-gradient(to top, $primary 0%,#202020 100%);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 5;
}