#calendar {
    margin-bottom: 10rem;
    .mec-wrap {
        width: 100%;
    }
    .mec-color-hover,
    .event-color,
    .mec-timetable-event-span span {
        @include media-breakpoint-up(md) {
            vertical-align: middle;
        }
    }

    .mec-color-hover {
        display: block;
        @include media-breakpoint-up(md) {
            display: inline-block;
        }
    }
    .event-color {
        width: 25px;
        height: 10px;
        border-radius: unset;
    }

    .mec-calendar.mec-calendar-daily .mec-calendar-d-top h3 {
        font-size: 18px;
    }
}