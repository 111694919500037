#wrapper-footer {
    background-color: $primary;
    margin-top: 10rem;
    & * {
        color: $white;
        font-size: 1rem;
        font-family: $font-family-sans-serif;
    }
    .footer-info {
        padding: 0;
        @include media-breakpoint-up(md) {
            padding: .5rem 1rem;
        }
    }

    .logo-footer {
        width: 50px;
    }

    .sub-footer {
        align-items: center;

        div {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            align-items: center;
            border-top: solid 1px $gray-700;
        }

        a {
            text-decoration: underline;
        }
    }
    .nav-link {
        &:hover {
            text-decoration: underline;
        }
    }
    .last-column {
        #main-menu {
            .menu-item {
                pointer-events: inherit;
                .nav-link {
                    font-weight: 300 !important;
                }
            }
        }
    }
}