#newsletterForm {
    height: 40vh;
    min-height: 400px;
    overflow: hidden;
    background-image: url(../assets/img/stensen-logo-fill.svg);
    background-repeat: no-repeat;
    background-position: center right;

    .container {
        height: 100%;

        .newsletter-content {
            text-align: center;

            .acceptance-35 {
                label {
                    position: relative;

                    .wpcf7-list-item-label {
                        position: absolute;
                        left: 0;
                        transform: translateX(5%);
                        text-align: initial
                    }
                }
            }

            .ajax-loader {
                position: absolute;
                bottom: -20px;
            }

            .newsletter-title {
                color: $section-title;
            }

            .newsletter-mail {
                width: 100%;
                padding: 8px;
                background-color: #F8F8F8;
                border: none;
                border-radius: 0;
            }

            .newsletter-submit {
                width: 20%;
                padding: 8px;
                background-color: $blue;
                color: $white;
                border: none;
                cursor: pointer;
                border-radius: 0;
            }
        }
    }
}