@media print {
    #wrapper-navbar,
    #eventHeader,
    #wrapper-footer,
    .section-heading,
    .section-title,
    #printSection,
    #newsletterForm {
        display: none !important;
    }
    // .printable * {
    //     display: block !important;
    // }
}  