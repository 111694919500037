.navbar {
    position: fixed;
    width: 100%;
    z-index: 999;
    background-color: $primary;
    box-shadow: 0 10px 50px -20px #5d5d5d;
    @include media-breakpoint-up(md) {
        background-color: $white;
    }
    #navbarNavDropdown {
        .brand-link {
            .logo-full {
                display: none;
                @include media-breakpoint-up(lg) {
                    width: 250px;
                    display: block;
                }
            }
        }
    }
}
.navbar-light {
    .contaner-fluid__stretch {
        align-items: stretch;
        .navbar-brand {
            position: relative;
            .brand-link{
                .brand-logo {
                    width: 50px;
                    height: auto;
                }
                .brand-logo-mobile {
                    width: 180px;
                    height: 100%;
                }
            }
            // &:after {
            //     @include media-breakpoint-up(lg) {
            //         content: url(../assets/img/logo-stensen-text.svg);
            //         position: absolute;
            //         top: 50%;
            //         transform: translateY(-50%);
            //         right: -330%;
            //         width: 250px;
            //     }
            // }
        }    
        .dropdown-menu {
            background-color: $primary;
            .menu-item {
                @include media-breakpoint-up(md) {
                    border-left: solid 0 $blue;
                    transition: border-left 250ms cubic-bezier(0.71,-0.01, 0.02, 1);
                    &:hover {
                        border-left: solid 5px $blue;
                    }
                }
                .dropdown-item {
                    color: $secondary;
                    transition: color 200ms ease-in-out;
                    border-radius: 0;
                    &:hover,
                    &:focus {
                        color: $gray-500;
                        background-color: $primary;
                        outline: none;
                    }
                }
            }
            @include media-breakpoint-up(md) {
                margin: 0;
                border: none;
                border-radius: 0;
            }
        }
        @include media-breakpoint-up(md) {
            #main-menu {
                height: 100%;
                display: flex;
                align-items: center;
                .menu-item {
                    height: 100%;
                    display: flex;
                    align-items: center;
                }
            }
        }

        .navbar-nav {
            .nav-link,
            .dropdown-item {
                font-family: $nav-font-family;
                font-weight: $font-weight-normal;
                color: $gray-900;
            }
            .nav-link {
                color: $secondary;
                font-size: 1.19rem;
                padding-left: 1rem;
                @include media-breakpoint-up(md) {
                    color: $primary;
                    font-size: 1.1rem;
                    padding: .5rem 1rem;
                }  
            }
        }
        .nav--search {
            position: relative;
            .nav--search--wrap {
                height: 100%;
                background-color: $primary;
                position: relative;
                z-index: 998;
                overflow: hidden;
                width: 68px;
                cursor: pointer;
                .nav--search--icon {
                    //padding: 0 1.5rem;
                    position: absolute;
                    height: 100%;
                    z-index: 999;
                    fill: white;
                    width: 100%;
                    cursor: pointer;
                    top: -35%;
                    transition: top 350ms cubic-bezier(0.71,-0.01, 0.02, 1);
                    &::selection {
                        background: none;
                    }
                }
            }
            .search--input--wrap {
                position: absolute;
                right: 0;
                display: block;
                z-index: 2;
                overflow: hidden;
                height: 0;
                transition: height 350ms cubic-bezier(0.71,-0.01, 0.02, 1) 250ms;
                .search--input {
                    border-width: 2px;
                    border-color: $primary;
                    padding: 1rem 2rem;
                    width: 270px;
                    
                }
            }
            &.show {
                .search--input--wrap {
                    height: 100%;
                }
                .nav--search--icon {
                    top: 35%;
                }
            }
        } 
    }

}

