#altreAttivita {
    position: relative;
    margin: 3rem auto;
    padding: 3rem 0 6rem 0;
    background: linear-gradient(to top, $primary 0%,#202020 100%);
    &:after {
        content: '';
        position: absolute;
        background-color: #202020;
        height: 60px;
        width: 100%;
        top: -59px;
        clip-path: polygon(0 0, 0 100%, 105% 100%);

    }
    .section-heading:after {
        background-color: #3c3c3c;
    }
    .single-event {
        color: $secondary;
        a {
            color: $secondary;
        }
    }
}